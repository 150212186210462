export default {
    title: 'G2mail',
    business: 'Business',
    channels: 'Channels',
    total: 'Total:',
    page_size: '/page',
    url_title: 'Temporary URL',
    oauth_title: 'Email Auth',
    used_title: 'Used Email',
    notice: 'Notice',
    price: 'Price',
    console: 'Console',
    create_account: 'Create User',
    user: 'Username',
    password: 'Password',
    role_admin: 'Admin',
    role_user: 'User',
    quit: 'Log out',
    buy_count: 'Amount',
    buy_count_msg: 'Please enter the purchase quantity.',
    buy: 'Buy',
    business_type: 'Business type: ',
    bill_price: 'Price: ',
    bill_buy_count: 'Amount: ',
    pay_amount: 'Payment amount: ',
    confirm_order: 'Confirm order',
    cancel: 'Cancel',
    pay: 'Payment',
    login: 'Log in',
    reset: 'Reset password',
    sign_up: 'Sign up',
    confirm_pass: 'Confirm password',
    email: 'Email',
    email_code: 'Email code',
    send_code: 'Send code',
    submit: 'Submit',
    stock: 'Stock',
    query: 'Query',
    create_time: 'Create Time',
    start_time: 'Start Time',
    end_time: 'End Time',
    username: 'Username',
    remark: 'Remark',
    recharge_title: 'Recharge',
    recharge_amount: 'Recharge Amount',
    used_email: 'Used Email',
    detail: 'Description',
    footer_desc: 'Temporary Email is a tool designed for short-term email receiving. It allows users to quickly generate a temporary email address to receive verification emails or other non-permanent emails without having to register.',
    recharge_tip: ` <div style="margin-bottom: 5px">1. <b>The final recharge amount</b> is subject to the payment page. Please pay attention to <b>the decimal
        amount</b> when paying. If the amount is wrong, it will not be automatically credited and you need to contact
        customer service.
      </div>
      <div>2. The recharge timeout is <b>15 minutes</b>. Please do not pay after the timeout. If you pay after the timeout,
        please contact customer service.
      </div>`,
    used_detail: `<li>This product is an email address that has received a business verification code from the platform, such as Telegram, Apple, Instagram, etc.</li>
<li>The email address can survive for more than 24 hours</li>
<li>10:30 ~ 12:00 and 22:30 ~ 24:00(GMT+8) time range does not allow purchase</li>
<li>The email address format is: email address----password----recovery email address</li>`,
    type_detail_1: 'Gmail API - Receive any  ',
    type_detail_2: ' email message',
    delimiter: 'Delimiter(Default is "----")',
    email_front: 'Email at the start',
    email_after: 'Email at the end',
    url_detail: `<li>The default link format is: http://0.0.0.0:9060/mail/code?key=pY7CQ4Mo1b%252FNX4YwP6KpWcineJfTbKdCZN795CufMoV1DGhsOST%252BctZm%252BeE7%252FtxwPyYxatTh8KXWy3iyK3%252B9sjMVgcmUtBc1hMzGqsGnasfKdxbC2C8iR5HsYYY1vMLq&fd=test@g2mail.shop----xxxxxxxxxx@gmail.com</li>
<li>xxxxxxxxxx@gmail.com is the email address</li>
<li>Custom formats require deleting separators and email addresses before requesting</li>
<li>The verification code has been received and returned in the format: <br />{
"email_code": "888888",
"content": "Test code is 888888\\n",
"subject": "Test",
"status": 1,
"message": "Code received"
}</li>`,
    login_rule: {
        email_error: 'Email error',
        user_error: 'Username error',
        no_user: 'Please enter username',
        user_len_error: 'Length between 6 and 18 characters',
        user_format_error: 'Must start with an English letter',
        no_pass: 'Please enter password',
        pass_len_error: 'Length between 6 and 18 characters',
        no_confirm_pass: 'Please enter confirm password',
        no_email_code: 'Please enter email code',
        confirm_pass_error: 'Confirm password does not match',
    },
    home: {
        select_date: 'Select date',
        register_count: 'Register count: ',
        balance: 'Balance:',
        freeze: 'Locking:',
        secret_key: 'API key:',
        create_time: 'Create time:',
        email: 'Email:',
        freeze_tip: 'Amount being traded'
    },
    mail_link: {
        batch_id: 'Batch ID',
        amount: 'Amount',
        type: 'Business Type',
        action: 'Action',
        download: 'Download',
        detail: 'Detail',
        operator: 'Operator'
    },
    link_task: {
        batch_id: 'Batch ID',
        address: 'Address',
        type: 'Business Type',
        state: 'State',
        price: 'Price',
        receive_time: 'Received Time',
        code_time: 'Request Time',
        code: 'Email Code',
        input_batch_id: 'Please enter batch ID',
    },
    api_task: {
        address: 'Address',
        type: 'Business Type',
        state: 'State',
        charge: 'Charge',
        price: 'Price',
    },
    capital: {
        amount: 'Amount',
        operator: 'Operator',
        type: 'Type'
    },
    pay_page: {
        minutes: 'Minutes',
        seconds: 'Seconds',
        tip: 'Address copied.',
        timeout1: 'Payment expired.',
        timeout2: 'Please re-initiate payment.',
        success: 'Recharge successful',
        success_tip: 'Payment has been successful.',
        cancel_tip: 'Payment canceled.',
        tip_warn: `<div>1. Please pay attention to the decimal places of the payment amount. The transfer amount must be exactly the same as the amount displayed on the page.</div>
<div>2. Please pay before the countdown ends.</div>
<div>3. Please check the payment address carefully. The platform is not responsible for any losses caused by incorrect payment address.</div>
<div>4. If you have any questions, please feel free to contact customer service.</div>`
    },
    recharge: {
        address: 'Address',
        state: 'State',
        money: 'Amount($)',
        expire_time: 'Expire Time',
        action: 'Action',
        cancel: 'Cancel',
        confirm: 'Confirm',
        pay: 'Pay',
        tip_msg: 'Confirm cancellation of payment?',
        tip_title: 'Kind tips'
    },
    notice_page:{
        title: 'Title',
        content: 'Content',
    },
    history:{
        batch_id: 'Batch ID',
        amount: 'Amount',
        price: 'Price($)',
        action: 'Action',
        download: 'Download',
    }
}