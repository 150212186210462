export function formatDate(time) {
    const date = new Date(time)
    const Y = date.getFullYear()
    const M = date.getMonth() + 1
    const D = date.getDate()
    const h = date.getHours()
    const m = date.getMinutes()
    const s = date.getSeconds()
    return `${Y}-${M}-${D} ${add0(h)}:${add0(m)}:${add0(s)}`
}

export function formatBool(bool, trueText, falseText) {
    const color = bool ? 'green' : 'red'
    const text = bool ? trueText : falseText
    return `<span style="color: ${color}">${text}</span>`
}

export function add0(str) {
    str += ''
    if (str.length < 2) return '0' + str
    return str
}

export function formatMoney(money) {
    return Number((money / 10000).toFixed(4))
}

export function toMoney(money) {
    return money * 10000
}

export function getPrice(arr, type) {
    if (!arr) return
    for (const item of arr) {
        if (item.key == type) {
            return item.value;
        }
    }
}

export function hasDuplicates(arr, keyProperty) {
    const seen = new Set();

    for (const obj of arr) {
        const identifier = keyProperty.map(key => obj[key]).join('|'); // 基于 key 生成唯一字符串
        if (seen.has(identifier)) {
            return true; // 找到重复
        }
        seen.add(identifier);
    }
    return false; // 没有重复
}

export function downloadFileFromURL(url, filename) {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function formatDateInterval(date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setHours(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export const copyText = (text, callback) => {
    let tag = document.createElement('textarea')
    tag.setAttribute('id', 'cp_textarea')
    tag.value = text
    document.getElementsByTagName('body')[0].appendChild(tag)
    document.getElementById('cp_textarea').select()
    document.execCommand('copy')
    document.getElementById('cp_textarea').remove()
    if (typeof callback === 'function') callback()
}