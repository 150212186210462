import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const actions = {
    setUserInfo(context, value) {
        context.commit('setUserInfo', value);
    },
};

const mutations = {
    setUserInfo(state, value) {
        state.userInfo = value;
    },
    setTableHeight(state, value) {
        state.tableHeight = value
    },
    setTableHeightNoPage(state, value) {
        state.tableHeightNoPage = value
    },
    setTableHeightNoBtn(state, value) {
        state.tableHeightNoBtn = value
    },
    isMobile(state, value) {
        state.mobile = value
    }
};

const state = {userInfo: {}, tableHeight: 0, tableHeightNoPage: 0, tableHeightNoBtn: 0, mobile: false};

export default new Vuex.Store({
    actions,
    mutations,
    state,
});
