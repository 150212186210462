<template>
  <el-pagination
      style="margin-top: 10px;text-align: right"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="current"
      layout="prev, pager, next, ->,slot"
      :total="total">
    <div>
      <el-select v-model="current" size="mini" @change="handleSizeChange">
        <template v-for="item in pageSizes">
          <el-option :key="item" :label="item + $t('page_size')" :value="item"></el-option>
        </template>
      </el-select>
      <span style="font-weight: 400;font-size: 0.75rem">{{ $t('total') }}{{ total }}</span>
    </div>
  </el-pagination>

</template>
<script>
export default {
  props: {
    total: {},
    currentPage: {
      default: 1
    },
    pageSizes: {
      default: () => ([10, 20, 50, 100])
    },
    pageSize: {
      default: 20
    },
  },
  data() {
    return {
      current: 20
    }
  },
  mounted() {
    this.current = this.pageSize
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange(val) {
      this.$emit('current-change', val)
    }
  }
}
</script>