import axios from 'axios'
import {Message} from 'element-ui';

axios.defaults.baseURL = '/api'

axios.interceptors.request.use(config => {
    config.headers['token'] = localStorage.getItem('token')
    return config
})

axios.interceptors.response.use(
    function (response) {
        if (response.config.method !== 'get') {
            Message.success('Success')
        }
        return response.data;
    },
    function (error) {
        if (!error.response?.config?.url.includes('/user/current')) {
            Message.error(error.response?.data?.msg || 'Error')
        }
        if (error.response?.data?.code === 50001) {
            Message.error(error.response?.data?.msg || 'Error')
        }
        if (error.response?.data?.code === 40001) {
            if (location.pathname?.startsWith('/console')) {
                Message.error(error.response?.data?.msg || 'Error')
                setTimeout(() => {
                    window.location.href = '/login?redirect=' + location.pathname
                }, 1000)
            }
            localStorage.removeItem('token')
        }
        return error.response?.data;
    },
);
export default axios