<template>
  <div class="home">
    <div class="bg-white">
      <template v-if="$store.state.userInfo.role >= role.SYSTEM">
        <el-date-picker
            v-model="date" size="small"
            align="right" @change="changeDate"
            type="date" :clearable="false"
            :placeholder="$t('home.select_date')"
            :picker-options="pickerOptions">
        </el-date-picker>
        <div id="main" class="echarts-wrapper"></div>
      </template>
      <template v-else>
        <div class="adv">
          <div>
            <el-avatar icon="el-icon-user-solid" :size="80" style="font-size: 50px"></el-avatar>
          </div>
          <b style="margin-left: 10px">{{ $store.state.userInfo.username }}</b>
        </div>
        <div class="info-wrapper">
          <div class="info-item">
            <div class="info-title">{{ $t('home.balance') }}</div>
            <b> {{ formatMoney($store.state.userInfo.balance) }}$</b>
            <i class="el-icon-refresh refresh" @click="refresh"></i>
            <el-button type="danger" size="mini" @click="dialogVisible=true">{{ $t('recharge_title') }}</el-button>
          </div>
          <div class="info-item">
            <div class="info-title">{{ $t('home.freeze') }}</div>
            <b>{{ formatMoney($store.state.userInfo.freeze) }}$</b>
            <el-tooltip class="item" effect="dark" :content="$t('home.freeze_tip')" placement="right">
              <i class="el-icon-question" style="color: #8c939d;margin-left: 5px"></i>
            </el-tooltip>
          </div>
          <div class="info-item">
            <div class="info-title">{{ $t('home.email') }}</div>
            <b>{{ $store.state.userInfo.email || '-' }}</b>
          </div>
          <div class="info-item">
            <div class="info-title">{{ $t('home.secret_key') }}</div>
            <b>{{ $store.state.userInfo.secret_key || '-' }}</b>
          </div>
          <div class="info-item">
            <div class="info-title">{{ $t('home.create_time') }}</div>
            <b>{{ formatDate($store.state.userInfo.create_time) }}</b>
          </div>
        </div>
      </template>
    </div>

    <el-dialog :width="$store.state.mobile? '80%' :'450px'"
               :title="$t('recharge_title')" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form :model="submitData" ref="submitData" label-position="top" size="small">
        <el-form-item :label="$t('recharge_amount') + '($)'" prop="money"
                      :rules="[{ required: true, message: $t('recharge_amount'), trigger: 'blur' }]">
          <el-input-number v-model="submitData.money" :min="1" :precision="4" style="width: 100%"></el-input-number>
        </el-form-item>
      </el-form>
      <div v-html="$t('recharge_tip')"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="submit" size="small" :loading="loading">{{ $t('submit') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {formatDateInterval, formatMoney, formatDate} from '@/morejs/tools'
import role from '@/morejs/roles'

export default {
  name: 'home',
  data() {
    return {
      submitData: {money: 1},
      todayChart: null,
      formatMoney,
      formatDate,
      role,
      lock: false,
      registerTotal: 0,
      date: null,
      dates: [],
      userInfo: {},
      queryObj: {from: '', to: ''},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      dialogVisible: false,
      loading: false,
    }
  },
  watch: {
    '$store.state.userInfo': {
      handler(userInfo) {
        if (userInfo?.username) {
          this.userInfo = userInfo
          this.$nextTick(() => {
            this.getData(userInfo)
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getData(this.$store.state.userInfo)
  },
  methods: {
    getData(userInfo) {
      if (!userInfo?.username) return
      if (this.lock) return
      this.lock = true
      if (userInfo.role >= role.SYSTEM) {
        const date = new Date()
        this.dates = [formatDateInterval(date), formatDateInterval(date)]
        this.queryObj.from = this.dates[0]
        this.queryObj.to = this.dates[1]
        this.date = formatDateInterval(date)
        const chartDom = document.getElementById('main');
        this.todayChart = echarts.init(chartDom);
        this.today()
      }
    },
    refresh() {
      const loading = this.$loading({
        lock: true,
        target: '.home',
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      });
      this.$axios.get('/user/current').then(res => {
        if (res.code === 0) {
          this.$store.commit('setUserInfo', res.data)
        }
      }).finally(() => {
        loading.close()
      })
    },
    today() {
      const loading = this.$loading({
        lock: true,
        target: '.home',
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      });
      this.$axios.get('/statistics/today', {params: {date: this.date}}).then(res => {
        if (res?.code === 0) {
          const data = res.data
          this.registerTotal = data.register
          const option = []
          data.data.forEach(item => {
            option.push({value: item.api, name: 'API - ' + item.type.name})
            option.push({value: item.link, name: 'URL - ' + item.type.name})
          })
          this.init(option, data.register)
        }
      }).finally(() => {
        loading.close()
      })
    },
    submit() {
      this.loading = true
      this.$axios.put('/user/recharge', this.submitData).then(res => {
        if (res?.code === 0) {
          this.dialogVisible = false
          const newRoute = this.$router.resolve({path: '/pay?id=' + res.data.id});
          window.open(newRoute.href, '_blank');
        }
      }).finally(() => {
        this.loading = false
      })
    },
    init(data, count) {
      const option = {
        title: {
          text: this.$t('home.register_count') + count,
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: true,
          feature: {}
        },
        series: [
          {
            name: '今日统计',
            type: 'pie',
            radius: ['10%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && this.todayChart.setOption(option);
    },
    changeDate(val) {
      this.date = val
      this.today()
    },
    changeRangDate(val) {
      this.queryObj.from = val[0]
      this.queryObj.to = val[1]
    }
  }
}
</script>
<style scoped>
.echarts-wrapper {
  height: calc(100vh - 125px);
}

.adv {
  display: flex;
  align-items: center;
  padding: 20px;
}

.info-wrapper {
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.info-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.info-title {
  width: 5rem;
  text-align: right;
  margin-right: 5px;
}

.refresh {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 10px 0 5px;
  cursor: pointer;
}

.refresh:hover {
  color: #409EFF;
}
</style>
